import {handleUnauthorizedUserError} from '../utils';
import {development} from './config';

import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
	axios.defaults.baseURL = development.protocol + '://' + development.httpServer.host + ':'
		+ development.httpServer.port + '/apis' ;
}else{
	axios.defaults.baseURL = '/apis';
}

axios.defaults.withCredentials = 'include';
axios.interceptors.response.use(response => {
	return response;
},
error => {
	if (error.response.status && error.response.status === 401) {
		handleUnauthorizedUserError();
	}

	return Promise.reject(error);
});

export default axios;