import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login} from '../actions/authActions';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onFormSubmit = (event) => {
		event.preventDefault();
		this.props.login({email: this.state.email, password: this.state.password});
	};

	renderDefault() {
		return (
			<div className='ui dashboard one column stackable grid login'>
				<div className='column right floated five wide'>
					<div className='ui center aligned grid login'>
						<div className='column ten wide'>
							<img className='ui small centered image' src='logo.png' alt='definitechs_logo'/>
							<h1 className='ui center aligned header p-b-20'>
								Login
							</h1>
							<form className='ui form' onSubmit={this.onFormSubmit}>
								<div className='field'>
									<input className='input' type='email' name='email' placeholder='Email'
										   onChange={e => this.setState({email: e.target.value})}/>
								</div>
								<div className='field'>
									<input className='input' type='password' name='password' placeholder='Password'
										   onChange={e => this.setState({password: e.target.value})}/>
								</div>
								<button className='ui inverted primary button' type='submit'
									style={{marginTop: '10px'}}>Submit
								</button>
								<div className='field'>
									<p className={`error ${this.props.loginError ? 'visible' : 'hidden'}`}>{this.props.loginError}</p>
								</div>
								<a href="/register">Request an Account</a>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.isLoggedIn) {
			return null;
		}

		return this.renderDefault();
	}
}

const mapStateToProps = ({auth}) => {
	return {isLoggedIn: auth.isLoggedIn, loginError: auth.loginError ? auth.loginError.message : null};
};

export default connect(mapStateToProps, {login})(Login);