import React, {Component} from 'react';
import RepositoryForm from '../Form/Repository/RepositoryForm';
import RemoteActions from '../Form/RemoteActions';

class RepositoryModalForm extends Component {

	render() {
		return (
			<div id="repository-modal-form" className="ui modal">
				<div className="header">
					Add Repository
				</div>
				<div className="content">
					<RepositoryForm
						company={this.props.company}
					/>
				</div>
				<div className="actions">
					<RemoteActions form="repository"/>
				</div>
			</div>
		);
	}
}

export default RepositoryModalForm;
