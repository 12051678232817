import {combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import repositories from './Repository/repositoriesRedeucer';
import authRepository from './authRepository';
import infrastructureReducer from './infrastructuresReducer';
import menuItemsReducer from './menuItemsReducer';
import selectedInfrastructureReducer from './selectedInfrastructureReducer';
import userReducer from './userReducer';
import tableReducer from './tableReducer';
import dateTags from './dateTagReducer/dateTags';
import selectedDateTag from './dateTagReducer/selectedDateTag';
import sidebar from './sidebar';
import repositoryFolderReducer from './Repository/repositoryFolderReducer';
import accessibleOwnerReducer from './Repository/accessibleOwnerReducer';

import {RESET_APP} from '../actions/types';
import projectReducer from "./projectReducer";
import fileReducer from "./fileReducer";

const appReducers = combineReducers({
	form: formReducer,
	auth: authReducer,
	authRepository: authRepository,
	user: userReducer,
	infrastructures: infrastructureReducer,
	menuItems: menuItemsReducer,
	selectedInfrastructure: selectedInfrastructureReducer,
	table: tableReducer,
	dateTags: dateTags,
	selectedDateTag: selectedDateTag,
	repositories: repositories,
	repositoryFolder: repositoryFolderReducer,
	accessibleOwners: accessibleOwnerReducer,
	sidebar: sidebar,
	projects: projectReducer,
	files: fileReducer,
});

const rootReducers = (state, action) => {
	if (action.type === RESET_APP) {
		state = undefined;
	}

	return appReducers(state, action);
};

export default rootReducers;