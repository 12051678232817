import {
	FETCH_DIRECTORY_LIST_REQUESTED,
	FETCH_DIRECTORY_LIST_RECEIVED,
	FETCH_DIRECTORY_LIST_FAILURE,
	FETCH_FILES_LIST_REQUESTED,
	FETCH_FILES_LIST_RECEIVED,
	FETCH_FILES_LIST_FAILURE, REPOSITORY_DIRECTORY_DELETED, REPOSITORY_FILE_DELETED,
} from '../types';
import axios from '../../apis/apiclient';

export const fetchDirectoryList = (repositoryId, itemId, directoryPrefix) => dispatch => {
	const url = `/repositories/${repositoryId}/items/${itemId}/directories`;
	dispatch({
		type: FETCH_DIRECTORY_LIST_REQUESTED,
	});
	axios.get(url, {
		params:{
			directory_prefix: directoryPrefix,
		}
	}).then(response => {
		return dispatch({
			type: FETCH_DIRECTORY_LIST_RECEIVED,
			payload: response.data,
		});
	}).catch(error => {
		return dispatch({
			type: FETCH_DIRECTORY_LIST_FAILURE,
			errorMessage: error.response,
		});
	});
};

export const fetchFilesList = (repositoryId, itemId, directoryPrefix) => dispatch => {
	const url = `/repositories/${repositoryId}/items/${itemId}/files`;
	dispatch({
		type: FETCH_FILES_LIST_REQUESTED,
	});
	axios.get(url, {
		params:{
			directory_prefix: directoryPrefix,
		}
	}).then(response => {
		return dispatch({
			type: FETCH_FILES_LIST_RECEIVED,
			payload: response.data,
		});
	}).catch(error => {
		return dispatch({
			type: FETCH_FILES_LIST_FAILURE,
			errorMessage: error.response,
		});
	});
};

export const uploadFile = (url, data, config, callback) => {
	axios.put(url, data, config)
		.then(function (res) {
			callback(null, res);
		})
		.catch(function (err) {
			callback(err);
		});
};

export const deleteDirectory = (repositoryId, itemId, path, directory) => dispatch =>  {
	const directory_path = `${path}${directory}`;
	const url = `repositories/${repositoryId}/items/${itemId}/directories`;
	axios.delete(url, {
		params:{
			directory_path: directory_path,
		}
	}).then(() => {
		dispatch({
			type: REPOSITORY_DIRECTORY_DELETED,
			payload: directory,
		});
	});
};

export const deleteFile = (repositoryId, itemId, path, file) => dispatch =>  {
	const directory_path = `${path}${file.Key}`;
	const url = `repositories/${repositoryId}/items/${itemId}/directories`;
	axios.delete(url, {
		params:{
			directory_path: directory_path,
		}
	}).then(() => {
		dispatch({
			type: REPOSITORY_FILE_DELETED,
			payload: file,
		});
	});
};

