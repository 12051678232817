import React from 'react';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';

const RemoteActions = ({ dispatch , form}) => (
	<>
		<button
			className="ui black deny button"
			onClick={() => dispatch(reset(form))}
		>
			Reset
		</button>
		<button
			className="ui positive right button"
			onClick={() => dispatch(submit(form))}
		>
			Submit
		</button>
	</>
);

export default connect()(RemoteActions);