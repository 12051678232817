import {
	FETCH_REPOSITORY_ITEMS_REQUESTED,
	FETCH_REPOSITORY_ITEMS_RECEIVED,
	FETCH_REPOSITORY_ITEMS_FAILURE,
	REPOSITORY_ITEM_CREATED,
	REPOSITORY_ITEM_UPDATED,
	REPOSITORY_ITEM_DELETED,
	REPOSITORY_ITEM_SELECTED,

} from '../../actions/types';

const repositoryItemReducer = (state , action) => {
	switch (action.type) {
		case FETCH_REPOSITORY_ITEMS_REQUESTED:
			return Object.assign({}, state, {
				data: state.data.map(repository => repository._id === state.selectedRepository ?
					{
						...repository,
						fetchingItems: true,
						items: undefined,
						fetchedError: undefined,
					}
					: repository)
			});
		case FETCH_REPOSITORY_ITEMS_RECEIVED:
			return Object.assign({}, state, {
				data: state.data.map(repository => repository._id === state.selectedRepository ?
					{
						...repository,
						fetchingItems: false,
						items: action.payload,
					}
					: repository)
			});
		case FETCH_REPOSITORY_ITEMS_FAILURE:
			return Object.assign({}, state, {
				data: state.data.map(repository => repository._id === state.selectedRepository ?
					{
						...repository,
						fetchingItems: false,
						fetchedError: action.errorMessage,
					}
					: repository)
			});
		case REPOSITORY_ITEM_CREATED:
			return Object.assign({}, state, {
				data: state.data.map(repository => repository._id === state.selectedRepository ?
					{
						...repository,
						items: [...repository.items, action.payload],
					}
					: repository)
			});
		case REPOSITORY_ITEM_UPDATED:
			return Object.assign({}, state, {
				data: state.data.map(repository => repository._id === state.selectedRepository ?
					{
						...repository,
						items: repository.items.map(item => item._id === action._id ? action.updatedValue: item),
					}
					: repository)
			});
		case REPOSITORY_ITEM_DELETED:
			return Object.assign({}, state, {
				data: state.data.map(repository => repository._id === state.selectedRepository ?
					{
						...repository,
						items: repository.items.filter(item => item._id !== action._id),
					}
					: repository)
			});
		case REPOSITORY_ITEM_SELECTED:
			return Object.assign({}, state, {
				selectedItem: action.payload,
			});
		default:
			return state;
	}
};

export default repositoryItemReducer;