import {
	FETCH_TABLES,
	FETCH_TABLE_ITEMS,
} from '../actions/types';

const initialState = {
	tables: [],
};

const tableReducer = (state = initialState, action) => {
	let newTables = null;
	switch (action.type) {
		case FETCH_TABLES:
			return Object.assign({}, state, {
				tables: action.payload,
			});
		case FETCH_TABLE_ITEMS:
			newTables = state.tables.map(table => table._id === action.table_id ? {
				...table,
				rows: action.payload.map(row => row.items),
			} : table);
			return Object.assign({}, state, {
				tables: newTables,
			});
		default:
			return state;
	}
};

export default tableReducer;