import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login, setLoginStatus} from '../actions/authActions';
import axios from '../apis/apiclient';
import _ from "lodash";
import {saveUserProfile} from "../actions/userActions";
import history from "../history";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmError: false,
      complete: false,
      errorMessage: "",
      hasError: false,
    };
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    if(this.state.confirmPass !== this.state.password){
      this.setState({confirmError: true});
      return;
    }
    const body = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      company: this.state.company,
      password: this.state.password,
    };

    axios.post('/account/register', body)
      .then(response => {
        if (response.data.success) {
          this.setState({complete: true});
        }
      })
      .catch(err => {
        this.setState({hasError: true, errorMessage: err.response.data.message ? err.response.data.message : 'Something went wrong'});
      });

  };

  render() {
    return (
      <div className='ui dashboard one column stackable grid login'>
        <div className='column right floated five wide'>
          <div className='ui center aligned grid login'>
            <div className='column fifteen wide'>
              <img className='ui tiny centered image' src='logo.png' alt='definitechs_logo'/>
              <h1 className='ui center aligned header p-b-20'>
                Request an Account
              </h1>
              {this.state.complete ?
                <p> Your request is submitted. We will contact you by email once your account is verified.</p> :
                <form className='ui form' onSubmit={this.onFormSubmit}>
                  <div className="two fields">
                    <div className='field'>
                      <input className='input' type='text' name='firstName' placeholder='First name'
                             required onChange={e => this.setState({firstName: e.target.value})}/>
                    </div>
                    <div className='field'>
                      <input className='input' type='text' name='lastName' placeholder='Last name'
                             required onChange={e => this.setState({lastName: e.target.value})}/>
                    </div>
                  </div>
                  <div className="two fields">
                    <div className='field'>
                      <input className='input' type='email' name='email' placeholder='Email'
                             required onChange={e => this.setState({email: e.target.value})}/>
                    </div>
                    <div className='field'>
                      <input className='input' type='text' name='company' placeholder='Company'
                             required onChange={e => this.setState({company: e.target.value})}/>
                    </div>
                  </div>
                  <div className="two fields">
                    <div className='field'>
                      <input className='input' type='password' name='password' placeholder='Password'
                             onChange={e => this.setState({password: e.target.value})}/>
                    </div>
                    <div className={`field ${this.state.confirmError ? "error" : ""}`}>
                      <input className='input' type='password' name='confirmPass' placeholder='Confirm password'
                             onChange={e => this.setState({confirmPass: e.target.value})}/>
                    </div>
                  </div>
                  <button className='ui inverted primary button' type='submit'
                          style={{marginTop: '10px'}}>Submit
                  </button>
                  <div className='field'>
                    <p
                      className={`error ${this.state.confirmError ? 'visible' : 'hidden'}`}>{this.state.confirmError ? "Confirm password should be same as password." : ""}</p>
                  </div>
                  <div className='field'>
                    <p className={`error ${this.state.hasError ? 'visible' : 'hidden'}`}>{this.state.errorMessage}</p>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  return {isLoggedIn: auth.isLoggedIn, loginError: auth.loginError ? auth.loginError.message : null};
};

export default connect(mapStateToProps, {login})(Register);