import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {getAllFiles} from "../actions/fileActions";
import PercentageModal from "./PercentageModal/PercentageModal";
import axios from "../apis/apiclient";
import {uploadFile} from "../actions/Repository/repositoryFolderActions";
import DirectoryContextMenu from "./Repository/DirectoryContextMenu";
import {ContextMenuTrigger} from "react-contextmenu";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class UserProfile extends React.Component {

  constructor(props){
    super(props);
    this.state={
      progress: 0,
      xhr: null,
    };
  }

  componentDidMount() {
    this.props.getAllFiles(this.props.selectedProject);
  }

  uploadFileProject = () => {
    if(!document.getElementById('file').files[0]) return;
    window.$('#percentage-modal').modal({}).modal('show');
    window.$('#progress').progress({
      percent:0,
    });

    this.setState({progress: 0});
    const item = this.props.selectedRepositoryItem;
    const url = `/project/signed-url`;

    const pathForRepositoryItem = `projects/${this.props.selectedProject.company}/${this.props.selectedProject.name}`;

    axios.post(url, {
      company: this.props.selectedProject.company,
      name: this.props.selectedProject.name,
      file: document.getElementById('file').files[0].name,
      fileType: document.getElementById('file').files[0].type,
    }).then(res => {
      const signedUrl = res.data.signedUrl;
      const data = new FormData();
      data.append('directory_path', pathForRepositoryItem);
      data.append('file', document.getElementById('file').files[0]);
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          this.setState({uploadProgress: percentCompleted});
          window.$('#progress').progress({
            percent:percentCompleted,
          });
          this.setState({progress: percentCompleted});
        },
        cancelToken: source.token,
        headers: {
          'Content-Type': document.getElementById('file').files[0].type,
        }
      };
      uploadFile(signedUrl, document.getElementById('file').files[0], config, (err) => {
        if(!err)
          axios.get('project/notify', {params:{
            project: this.props.selectedProject.name,
            file: document.getElementById('file').files[0].name,
          }});
          window.location.reload(true);
      });
    });
  };

  download(url) {
    // fake server request, getting the file url as response
    setTimeout(() => {
      const response = {
        file: url,
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
    }, 100);
  }

  download (url, fileName) {
    window.$('#percentage-modal').modal({}).modal('show');
    window.$('#progress').progress({
      percent:0,
    });

    const xhr = new XMLHttpRequest();
    this.setState({xhr: xhr});
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100 *100;
        this.setState({uploadProgress: percentComplete});
        window.$('#progress').progress({
          percent: percentComplete,
        });
        console.log(percentComplete);
        //yourShowProgressFunction(percentComplete);
      }
    };

    xhr.cancelToken =  source.token;
    xhr.onload = (event) => {
      if (xhr.status == 200) {
        console.log("lksdfsadsfdsfsdafdsds");
        this._saveBlob(xhr.response, fileName);
      }
      else {
        //yourErrorFunction()
      }
    };

    xhr.onerror = (event) =>{
      console.log(event);
      //yourErrorFunction()
    };

    xhr.send();
  };


  _saveBlob = (response, fileName) => {
    if(navigator.msSaveBlob){
      //OK for IE10+
      navigator.msSaveBlob(response, fileName);
    }
    else{
      this._html5Saver(response, fileName);
    }
  };

  _html5Saver = (blob , fileName) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
    window.$('#percentage-modal').modal({}).modal('hide');
  };

  handleDownload = (file) => {

    const params = {
      company: this.props.selectedProject.company,
      name: this.props.selectedProject.name,
      file: file,
    };

    const url = `/project/download-signed-url`;

    axios.post(url, params).then(res => {
      console.log(res);
      console.log(res.data.signedUrl);
      this.download(res.data.signedUrl, params.name);
      // const downloadUrl = res.data.signedUrl;
      // var downloading = window.downloads.download({
      //   url : downloadUrl,
      //   filename :  params.name,
      //   conflictAction : 'uniquify'
      // });
    });


  };

  handleCancelUpload = () => {
    source.cancel('Operation canceled by the user.');
    this.state.xhr.abort();
  };

  handleDelete = (file) => {
    const url = `project/file`;
    axios.delete(url, { params:{
      company: this.props.selectedProject.company,
      name: this.props.selectedProject.name,
      file: file,
    }})
      .then(res => {
        window.location.reload(true);
      })
  };

  render() {
    const {files} = this.props;
    return (
      <div className='ui segment repositories'>
        {files.isFetching &&
        <div className="ui active inverted dimmer">
          <div className="ui text large loader">Loading</div>
        </div>
        }
        {!files.isFetching && files.hasError &&
        <div className="ui warning message">
          <div className="header">
            {files.hasError.errorMessage}
          </div>
        </div>
        }
        {!files.isFetching && !files.hasError &&
        <>
          <label htmlFor="file" className="ui labeled icon button">
            <i className="file icon"/>
            Upload File</label>
          <input type="file" id="file" onChange={this.uploadFileProject} style={{display:'none'}}/>
          <PercentageModal
            uploadProgress={this.state.progress}
            onCancelUpload={this.handleCancelUpload}
          />
          <table id="projects-table" className="ui fixed single line table">
            <thead>
            <tr>
              <th style={{width:'50px'}} />
              <th>Name</th>
            </tr>
            </thead>
            <tbody>
            {files.data.map(file => {
                const menuId = 'directory-context-menu' + file.Key;
                return(
                  <Fragment key={file.Key}>
                    <DirectoryContextMenu
                      id={menuId}
                      onDelete={() => this.handleDelete(file.Key)}
                      onDownload={() => this.handleDownload(file.Key)}
                    />
                    <tr key={file.Key} >
                      <td>
                        <ContextMenuTrigger id={menuId}><i className="file icon"/></ContextMenuTrigger>
                      </td>
                      <td>
                        <ContextMenuTrigger id={menuId}>{file.Key}</ContextMenuTrigger>
                      </td>
                    </tr>
                  </Fragment>
                );
              }
            )}
            </tbody>
          </table>
        </>
        }
      </div>
    );
  }
}

const mapStateToProps = ({user, menuItems, projects, files}) => {
  return {
    menuItems: Object.keys(menuItems),
    companyName: user.company_name,
    companyLogo: user.company_logo,
    selectedProject: projects.selectedProject,
    files: files
  };
};

export default connect(mapStateToProps, {getAllFiles})(UserProfile);