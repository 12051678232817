import {
	FETCH_CATEGORY_ITEMS, UPDATE_CATEGORY_FILTERS, UPDATE_SELECTED_CATEGORY_FILTER,
	FETCH_CATEGORY_DETAIL, POST_CATEGORY_COMMENT
} from './types';

import axios from '../apis/apiclient';

export const fetchCategoryItems = (infrastructureId, date, categoryType) => async dispatch => {
	const categoryItems = await axios.get(`/infrastructures/${infrastructureId}/categories`, {
		params:{
			type: categoryType,
			date: date,
		}
	});

	dispatch({type: FETCH_CATEGORY_ITEMS, payload: {[categoryType]: categoryItems.data}});
};

export const updateCategoryFilters = (categoryType, filters) => ({
	type: UPDATE_CATEGORY_FILTERS,
	payload: {[categoryType]: filters}
});

export const updateSelectedFilter = (categoryType, filter) => ({
	type: UPDATE_SELECTED_CATEGORY_FILTER,
	payload: {[categoryType]: filter}
});

export const fetchCategoryItemDetails = (categoryItemId) => async dispatch => {
	const categoryItemDetails = await axios.get(`/infrastructures/categories/${categoryItemId}`);

	dispatch({type: FETCH_CATEGORY_DETAIL, payload: categoryItemDetails.data});
};

export const submitComment = (categoryItemId, comment) => async (dispatch) => {
	const commentResult = await axios.post(`/infrastructures/categories/${categoryItemId}/comments`, {comment});
	const categoryDetail = await axios.get(`/infrastructures/categories/${categoryItemId}`);

	dispatch({type: FETCH_CATEGORY_DETAIL, payload: categoryDetail.data});
	dispatch({type: POST_CATEGORY_COMMENT, payload: commentResult.data});
};