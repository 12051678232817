import React, {Component} from 'react';
import {connect} from 'react-redux';
import {submitComment} from '../../../actions/categoryActions';
import CategoryItemComments from './CategoryItemComments';

class CategoryItemCommentForm extends Component {
	state = {comment: ''};

	componentDidUpdate(prevProps) {
		if (prevProps.comments.length !== this.props.comments.length) {
			this.setState({comment: ''});
		}
	}

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value});
	};

	onSubmit = (e) => {
		e.preventDefault();
		if (this.state.comment.length !== 0) {
			this.props.submitComment(this.props.itemId, this.state.comment);
		}
	};

	render() {
		const categoryItemComments = this.props.comments.map(comment => (
			<CategoryItemComments key={comment._id} text={comment.text} date={comment.posted}
								  postedBy={comment.posted_by}/>
		));

		return (
			<div>
				<div className='ui horizontal divider'>Previous Comments</div>
				<div id='comments-container'>
					{categoryItemComments}
				</div>
				<br/>
				<div className='ui horizontal divider'>Comment Below</div>
				<form className='ui inverted form' onSubmit={this.onSubmit}>
					<div className='field'>
						<textarea rows='6' name='comment' onChange={this.onChange} value={this.state.comment}
								  placeholder={'Add a comment here'}/>
					</div>
					<div className='field in-comment in-center'>
						<button className='ui primary submit labeled icon button' type='submit'>
							<i className='icon edit'/> Add Comment
						</button>
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps({selectedInfrastructure}) {
	return {comments: selectedInfrastructure.selectedCategoryItem.comments};
}

export default connect(mapStateToProps, {submitComment})(CategoryItemCommentForm);