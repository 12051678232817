import {store} from '../index';
import {setLoginStatus, resetApp} from '../actions/authActions';

export const FILTER_SHOW_ALL = 'Show All';

const TABLE_COLUMNS = 16;

export const calculateGridColumnSize = (items, position) => {
	if (items[position + 1]) {
		return Math.ceil(TABLE_COLUMNS / (items.length + 1));
	} else {
		return TABLE_COLUMNS - (position * Math.ceil(TABLE_COLUMNS / (items.length + 1)));
	}
};

export const handleUnauthorizedUserError = () => {
	store.dispatch(setLoginStatus(false));
	store.dispatch(resetApp());
};

export const capitalize = (s) => {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const generateKey = (pre) => {
	let r = Math.random().toString(36).substring(10);
	return `${ pre }_${ r }`;
};

export const openInNewTab = (url) => {
	var win = window.open(url, '_blank');
	win.focus();
};