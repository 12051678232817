import {
	FETCH_REPOSITORIES_REQUESTED,
	FETCH_REPOSITORIES_RECEIVED,
	FETCH_REPOSITORIES_FAILURE,
	REPOSITORY_CREATED,
	REPOSITORY_UPDATED,
	REPOSITORY_DELETED,
	REPOSITORY_SELECTED,
} from '../../actions/types';
import repositoryItemReducer from './repositoryItemsReducer';

const initialState = {
	hasError: false,
	errorMessage: '',
	isFetching: false,
	selectedRepository: null,
	selectedItem: null,
	data:[],
};

const repositoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_REPOSITORIES_REQUESTED:
			return Object.assign({}, state, {
				hasError: false,
				errorMessage: '',
				isFetching: true,
			});
		case FETCH_REPOSITORIES_RECEIVED:
			return Object.assign({}, state, {
				isFetching: false,
				data: action.payload,
			});
		case FETCH_REPOSITORIES_FAILURE:
			return Object.assign({}, state,{
				hasError: true,
				errorMessage: action.errorMessage,
				isFetching: false,
			});
		case REPOSITORY_CREATED:
			return Object.assign({}, state, {
				data: [...state.data, action.payload]
			});
		case REPOSITORY_UPDATED:
			return Object.assign({}, state, {
				data: state.data.map(repository => repository._id === action._id ? action.updatedValue : repository)
			});
		case REPOSITORY_DELETED:
			return Object.assign({}, state, {
				data: state.data.filter(repository => repository._id !== action._id)
			});
		case REPOSITORY_SELECTED:
			return Object.assign({}, state, {
				selectedRepository: action.id,
			});
		default:
			return repositoryItemReducer(state, action);
	}
};

export default repositoryReducer;