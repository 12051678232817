import {
	FETCH_DIRECTORY_LIST_REQUESTED,
	FETCH_DIRECTORY_LIST_RECEIVED,
	FETCH_DIRECTORY_LIST_FAILURE,
	FETCH_FILES_LIST_REQUESTED,
	FETCH_FILES_LIST_RECEIVED,
	FETCH_FILES_LIST_FAILURE, REPOSITORY_DIRECTORY_CREATED, REPOSITORY_DIRECTORY_DELETED, REPOSITORY_FILE_DELETED,
} from '../../actions/types';

const initialState = {
	directories: {
		isFetching: false,
		data:[],
		hasError: false,
		errorMessage: '',
	},
	files: {
		isFetching: false,
		data:[],
		hasError: false,
		errorMessage: '',
	}
};

const repositoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_DIRECTORY_LIST_REQUESTED:
			return Object.assign({}, state, {
				directories:{
					hasError: false,
					errorMessage: '',
					isFetching: true,
				}
			});
		case FETCH_FILES_LIST_REQUESTED:
			return Object.assign({}, state, {
				files:{
					hasError: false,
					errorMessage: '',
					isFetching: true,
				}
			});
		case FETCH_DIRECTORY_LIST_RECEIVED:
			return Object.assign({}, state, {
				directories:{
					isFetching: false,
					data: action.payload,
				}
			});
		case REPOSITORY_DIRECTORY_CREATED:
			return Object.assign({}, state, {
				directories:{
					isFetching: false,
					data: [...state.directories.data, action.payload]
				}
			});
		case REPOSITORY_DIRECTORY_DELETED:
			return Object.assign({}, state, {
				directories:{
					isFetching: false,
					data: state.directories.data.filter(item => item !== action.payload),
				}
			});
		case FETCH_FILES_LIST_RECEIVED:
			return Object.assign({}, state, {
				files:{
					isFetching: false,
					data: action.payload,
				}
			});
		case FETCH_DIRECTORY_LIST_FAILURE:
			return Object.assign({}, state, {
				directories:{
					isFetching: false,
					hasError: true,
					errorMessage: action.errorMessage
				}
			});
		case FETCH_FILES_LIST_FAILURE:
			return Object.assign({}, state, {
				files:{
					isFetching: false,
					hasError: true,
					errorMessage: action.errorMessage
				}
			});
		case REPOSITORY_FILE_DELETED:
			return Object.assign({}, state, {
				files:{
					isFetching: false,
					data: state.files.data.filter(item => item.Key !== action.payload.Key),
				}
			});
		default:
			return state;
	}
};

export default repositoryReducer;