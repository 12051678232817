import React from 'react';

const ImagesGrid = (props) => {
	const images = props.images.map(image => {
		return (
			<div key={image} className='eight wide column'>
				<div className='ui image'>
					<img src={image} alt='item_grid_image'/>
				</div>
			</div>
		);
	});

	return (
		<div className='ui two column grid'>
			{images}
		</div>
	);
};

export default ImagesGrid;

