import React, {Component} from 'react';

class PercentageModal extends Component {

	handleCancel = () => {
		this.props.onCancelUpload && this.props.onCancelUpload();
	};

	render() {
		return (
			<div id="percentage-modal" className="ui small modal">
				<div className="header">Uploading...</div>
				<div className="content">
					{this.props.uploadProgress === 100 &&
						< div className="ui active dimmer inverted">
							<div className="ui indeterminate text loader">waiting for response...</div>
						</div>
					}
					{this.props.uploadProgress !== 100 &&
						<div className="ui teal progress" id="progress">
							<div className="bar"><div className="progress"/></div>
						</div>
					}
				</div>
				<div className="actions">
					<button className="ui cancel negative button" onClick={this.handleCancel}>Cancel</button>
				</div>
			</div>
		);
	}
}

export default PercentageModal;
