import _ from 'lodash';
import {
	FETCH_INFRASTRUCTURES,
	INFRASTRUCTURE_HOVER_TOGGLE,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_INFRASTRUCTURES:
			return {...state, ..._.mapKeys(action.payload, '_id')};
		case INFRASTRUCTURE_HOVER_TOGGLE:
			return {
				...state,
				[action.payload]: state[action.payload].isHovered ? _.omit(state[action.payload], 'isHovered') :
					{...state[action.payload], isHovered: true}
			};
		default:
			return state;
	}
};