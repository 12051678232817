import React from 'react';
import {connect} from 'react-redux';
import CategoryItemCommentForm from './CategoryItemCommentForm';
import Table from '../../Utility/Table';
import CategoryItemImagePositionsGrid from '../../Utility/ImagesGrid';

import history from '../../../history';

class CategoryItemDetails extends React.Component {

	navigateBack = () => {
		history.goBack();
	};

	render() {
		if (!this.props.selectedCategoryItem) {
			return null;
		}

		const {category_id, details, image_positions} = this.props.selectedCategoryItem;
		const {fullImageURL} = this.props.location.state;

		return (
			<div id='details-page'>
				<div id='close-header'>
					<div className="close-container" onClick={this.navigateBack}>
						<div className="left-right"/>
						<div className="right-left"/>
						<label className="close">close</label>
					</div>
				</div>
				<div className='ui container'>
					<img src={fullImageURL} className='item-img full' alt='item_full_image'/>
					<CategoryItemImagePositionsGrid images={image_positions}/>
					<Table rows={details.items}/>
					<CategoryItemCommentForm itemId={category_id}/>
				</div>
			</div>
		);
	}
}

function mapStateToProps({selectedInfrastructure}) {
	return {selectedCategoryItem: selectedInfrastructure.selectedCategoryItem};
}

export default connect(mapStateToProps)(CategoryItemDetails);