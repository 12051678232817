import _ from 'lodash';
import {
	SAVE_SELECTED_INFRASTRUCTURE,
	SAVE_INFRASTRUCTURE_CATEGORIES,
	FETCH_CATEGORY_ITEMS, UPDATE_CATEGORY_FILTERS,
	UPDATE_SELECTED_CATEGORY_FILTER,
	FETCH_CATEGORY_DETAIL, POST_CATEGORY_COMMENT
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case SAVE_SELECTED_INFRASTRUCTURE:
			return {...state, id: action.payload};

		case SAVE_INFRASTRUCTURE_CATEGORIES:
			return {
				...state,
				categories: action.payload.reduce((categories, category) => {
					categories[category] = {};
					return categories;
				}, {})
			};

		case FETCH_CATEGORY_ITEMS:
			let categoryType = Object.keys(action.payload)[0];
			return {
				...state, categories: {
					...state.categories,
					[categoryType]: {
						...state.categories[categoryType],
						items: _.mapKeys(action.payload[categoryType], '_id')
					}
				}
			};

		case UPDATE_CATEGORY_FILTERS:
		case UPDATE_SELECTED_CATEGORY_FILTER:
			categoryType = Object.keys(action.payload)[0];
			return {
				...state, categories: {
					...state.categories,
					[categoryType]: {
						...state.categories[categoryType],
						[Array.isArray(action.payload[categoryType]) ? 'filters' : 'selectedFilter']: action.payload[categoryType]
					}
				}
			};

		case FETCH_CATEGORY_DETAIL:
			return {...state, selectedCategoryItem: action.payload};

		case POST_CATEGORY_COMMENT:
			return {...state, commentPosted: action.payload};
		default:
			return state;
	}
};