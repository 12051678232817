import {
	SET_LOGIN_STATUS,
	SET_LOGIN_ERROR
} from '../actions/types';

const initialState = {
	isLoggedIn: false,
	loginError: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_LOGIN_STATUS:
			return {...state, isLoggedIn: action.payload};

		case SET_LOGIN_ERROR:
			return {...state, loginError: action.payload};

		default:
			return state;
	}
};