import {SAVE_USER_PROFILE} from './types';

import axios from '../apis/apiclient';

export const fetchUserProfile = () => async dispatch => {
	const response = await axios.get('/profile');

	dispatch(saveUserProfile(response.data));
};

export const saveUserProfile = (profileInfo) => ({type: SAVE_USER_PROFILE, payload: profileInfo});
