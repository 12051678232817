import {
  FETCH_FILES_REQUESTED,
  FETCH_FILES_RECEIVED,
  FETCH_FILES_FAILURE,
  FILES_CREATED,
} from '../actions/types';

const initialState = {
  hasError: false,
  errorMessage: '',
  isFetching: false,
  selectedProject: null,
  data:[],
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILES_REQUESTED:
      return Object.assign({}, state, {
        hasError: false,
        errorMessage: '',
        isFetching: true,
      });
    case FETCH_FILES_RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.payload,
      });
    case FETCH_FILES_FAILURE:
      return Object.assign({}, state,{
        hasError: true,
        errorMessage: action.errorMessage,
        isFetching: false,
      });
    case FILES_CREATED:
      return Object.assign({}, state, {
        data: [...state.data, action.payload]
      });
    default:
      return state;
  }
};

export default projectReducer;