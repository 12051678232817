import {SIDEBAR_CHANGE_VISIBILITY} from '../actions/types';

const initialState = {
	visibility: false,
};

const sidebar = (state = initialState , action) => {
	if(action.type === SIDEBAR_CHANGE_VISIBILITY){
		return Object.assign({}, state, {
			visibility: action.payload,
		});
	}
	else{
		return state;
	}
};

export default sidebar;