import React from 'react';

const TextField = ({
						 input,
						 label,
						 meta: { touched, error, warning }
					 }) => (
	<div className={'ui field ' + (touched && error ? 'error' : '')}>
		<label>{label}</label>
		<input {...input} placeholder={label} type="text" />
		{touched && warning && <div className="ui pointing yellow basic label">{warning}</div>}
		{touched && error && <div className="ui pointing red basic label">{error}</div>}
	</div>
);

export default TextField;