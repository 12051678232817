import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {clearStorage} from '../../localStorage';

const PrivateRoute = ({component: Component, auth, ...rest}) => {
	const isLoggedIn = auth.isLoggedIn;
	if (!isLoggedIn) {
		clearStorage();
	}

	return (
		<Route
			{...rest}
			render={props =>
				isLoggedIn ? (
					<Component {...props} />
				) : (
					<Redirect to={{pathname: '/login', state: {from: props.location}}}/>
				)
			}
		/>
	);
};

const mapStateToProps = ({auth}) => {
	return {auth};
};

export default connect(mapStateToProps)(PrivateRoute);