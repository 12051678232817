import {
  FETCH_PROJECTS_REQUESTED,
  FETCH_PROJECTS_RECEIVED,
  FETCH_PROJECTS_FAILURE,
  PROJECTS_CREATED,
  PROJECTS_SELECTED,
} from './types';
import axios from '../apis/apiclient';
import history from '../history';

export const getAllProjects = (company) =>  dispatch => {
  dispatch({
    type: FETCH_PROJECTS_REQUESTED,
  });

  axios.get('/project', { params:{ company: company }})
    .then(response => {
      dispatch({
        type: FETCH_PROJECTS_RECEIVED,
        payload: response.data,
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_PROJECTS_FAILURE,
        errorMessage: err.response.data,
      });
    });
};


export const createProject = (project) => dispatch => {
  axios.post('/project', project)
    .then(response => {
      dispatch({
        type: PROJECTS_CREATED,
        payload: project,
      })
    })
};

export const selectProject = (project) => dispatch => {
  dispatch({
    type: PROJECTS_SELECTED,
    payload: project,
  });
  history.push(`/project`);
};