import {
	ADD_INFRASTRUCTURE_MENU_ITEMS,
	UPDATE_ACTIVE_MENU_ITEM,
	UPDATE_INFRASTRUCTURE_MENU_ITEMS
} from '../actions/types';

const initialState = {
	profile: {
		isActive: true,
		isVisible: true
	},
	infrastructures: {
		isActive: false,
		isVisible: true
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_INFRASTRUCTURE_MENU_ITEMS:
			const {activeItem, items} = action.payload;
			let menuItems = items.reduce((items, item) => {
				items[item.toLowerCase()] = {isActive: item.startsWith(activeItem), isVisible: true};
				return items;
			}, {});
			return {...menuItems};
		case ADD_INFRASTRUCTURE_MENU_ITEMS:
			let newItems = action.payload.reduce((items, item) => {
				items[item.toLowerCase()] = {isActive: false, isVisible: true};
				return items;
			}, {});
			return Object.assign({}, state, newItems);
		case UPDATE_ACTIVE_MENU_ITEM:
			const item = action.payload;
			let newState = Object.assign({}, state);
			Object.keys(newState).forEach(key => {
				newState[key].isActive = false;
			});
			newState[item].isActive = true;
			return newState;
		default:
			return state;
	}
};