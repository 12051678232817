import React from 'react';
import dateformat from 'dateformat';

const CategoryItemComments = (props) => {
	return (
		<div className='ui segment center-comment'>
			<div className='comment'>
				<div className='comment-header'>
					<span className='avatar'>
						<i className='fas fa-comment-alt'/>
					</span>
					<div className='header'>{props.postedBy}</div>
					<div className='ui small meta label'>{dateformat(new Date(props.date), 'fullDate')}</div>
				</div>
				<div className='content'>
					<div className='description'>{props.text}</div>
				</div>
			</div>
		</div>
	);
};

export default CategoryItemComments;