// Login Action creator types
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const RESET_APP = 'RESET_APP';

//Login Repository Action creator types
export const SET_REPOSITORY_LOGIN_STATUS = 'SET_REPOSITORY_LOGIN_STATUS';
export const SET_REPOSITORY_LOGIN_ERROR = 'SET_REPOSITORY_LOGIN_ERROR';
export const RESET_LOGIN_REPOSITORY = 'RESET_LOGIN_REPOSITORY';

export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';

// Infrastructure Action creator types
export const FETCH_INFRASTRUCTURES = 'FETCH_INFRASTRUCTURES';
export const INFRASTRUCTURE_HOVER_TOGGLE = 'INFRASTRUCTURE_HOVER_TOGGLE';
export const SAVE_SELECTED_INFRASTRUCTURE = 'SAVE_SELECTED_INFRASTRUCTURE';
export const SAVE_INFRASTRUCTURE_CATEGORIES = 'SAVE_INFRASTRUCTURE_CATEGORIES';

//Menu Items Action creator types
export const UPDATE_INFRASTRUCTURE_MENU_ITEMS = 'UPDATE_INFRASTRUCTURE_MENU_ITEMS';
export const ADD_INFRASTRUCTURE_MENU_ITEMS = 'ADD_INFRASTRUCTURE_MENU_ITEMS';
export const UPDATE_ACTIVE_MENU_ITEM = 'UPDATE_ACTIVE_MENU_ITEM';

//Category Action creator types
export const FETCH_CATEGORY_ITEMS = 'FETCH_CATEGORY_ITEMS';
export const UPDATE_CATEGORY_FILTERS = 'UPDATE_CATEGORY_FILTERS';
export const UPDATE_SELECTED_CATEGORY_FILTER = 'UPDATE_SELECTED_CATEGORY_FILTER';

//Category Details Action creator types
export const FETCH_CATEGORY_DETAIL = 'FETCH_CATEGORY_DETAIL';
export const POST_CATEGORY_COMMENT = 'POST_CATEGORY_COMMENT';

// Table Actions creator types
export const FETCH_TABLE_ITEMS = 'FETCH_TABLE_ITEMS';
export const FETCH_TABLES = 'FETCH_TABLES';

// date tag types
export const DATE_TAGS_REQUESTED = 'DATE_TAGS_REQUESTED';
export const DATE_TAGS_RECEIVED = 'DATE_TAGS_RECEIVED';
export const DATE_TAGS_FAILED = 'DATE_TAGS_FAILED';

// date tag selected types
export const Date_TAG_SELECT = 'DATE_TAG_SELECT';
export const DATE_TAG_UNSELECT = 'DATE_TAG_UNSELECT';

// sidebar types
export const SIDEBAR_CHANGE_VISIBILITY = 'SIDEBAR_CHANGE_VISIBILITY';


// repository types
export const FETCH_REPOSITORIES_REQUESTED = 'FETCH_REPOSITORIES_REQUESTED';
export const FETCH_REPOSITORIES_RECEIVED = 'FETCH_REPOSITORIES_RECEIVED';
export const FETCH_REPOSITORIES_FAILURE = 'FETCH_REPOSITORIES_FAILURE';
export const REPOSITORY_CREATED = 'REPOSITORY_CREATED';
export const REPOSITORY_UPDATED = 'REPOSITORY_UPDATED';
export const REPOSITORY_DELETED = 'REPOSITORY_DELETED';
export const REPOSITORY_SELECTED = 'REPOSITORY_SELECTED';

// repository accessible owner types
export const FETCH_ACCESSIBLE_OWNER_REQUESTED = 'FETCH_ACCESSIBLE_OWNER_REQUESTED';
export const FETCH_ACCESSIBLE_OWNER_RECEIVED = 'FETCH_ACCESSIBLE_OWNER_RECEIVED';
export const FETCH_ACCESSIBLE_OWNER_FAILURE = 'FETCH_ACCESSIBLE_OWNER_FAILURE';

// repository items types
export const FETCH_REPOSITORY_ITEMS_REQUESTED = 'FETCH_REPOSITORY_ITEMS_REQUESTED';
export const FETCH_REPOSITORY_ITEMS_RECEIVED = 'FETCH_REPOSITORY_ITEMS_RECEIVED';
export const FETCH_REPOSITORY_ITEMS_FAILURE = 'FETCH_REPOSITORY_ITEMS_FAILURE';
export const REPOSITORY_ITEM_CREATED = 'REPOSITORY_ITEM_CREATED';
export const REPOSITORY_ITEM_UPDATED = 'REPOSITORY_ITEM_UPDATED';
export const REPOSITORY_ITEM_DELETED = 'REPOSITORY_ITEM_DELETED';
export const REPOSITORY_ITEM_SELECTED = 'REPOSITORY_ITEM_SELECTED';

//repository folder types
export const FETCH_DIRECTORY_LIST_REQUESTED = 'FETCH_DIRECTORY_LIST_REQUESTED';
export const FETCH_DIRECTORY_LIST_RECEIVED = 'FETCH_DIRECTORY_LIST_RECEIVED';
export const FETCH_DIRECTORY_LIST_FAILURE = 'FETCH_DIRECTORY_LIST_FAILURE';
export const REPOSITORY_DIRECTORY_CREATED = 'REPOSITORY_DIRECTORY_CREATED';
export const REPOSITORY_DIRECTORY_DELETED = 'REPOSITORY_DIRECTORY_DELETED';


export const FETCH_FILES_LIST_REQUESTED = 'FETCH_FILES_LIST_REQUESTED';
export const FETCH_FILES_LIST_RECEIVED = 'FETCH_FILES_LIST_RECEIVED';
export const FETCH_FILES_LIST_FAILURE = 'FETCH_FILES_LIST_FAILURE';
export const REPOSITORY_FILE_DELETED = 'REPOSITORY_FILE_DELETED';



export const FETCH_PROJECTS_REQUESTED = 'FETCH_PROJECTS_REQUESTED';
export const FETCH_PROJECTS_RECEIVED = 'FETCH_PROJECTS_RECEIVED';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const PROJECTS_CREATED = 'PROJECTS_CREATED';
export const PROJECTS_SELECTED = 'PROJECTS_SELECTED';

export const FETCH_FILES_REQUESTED = 'FETCH_REPOSITORIES_REQUESTED';
export const FETCH_FILES_RECEIVED = 'FETCH_FILES_RECEIVED';
export const FETCH_FILES_FAILURE = 'FETCH_FILES_FAILURE';
export const FILES_CREATED = 'FILES_CREATED';