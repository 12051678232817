import {
	FETCH_ACCESSIBLE_OWNER_REQUESTED,
	FETCH_ACCESSIBLE_OWNER_RECEIVED,
	FETCH_ACCESSIBLE_OWNER_FAILURE
} from '../../actions/types';

const initialState = {
	hasError: false,
	errorMessage: '',
	isFetching: false,
	data:[],
};

const accessibleOwnerReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ACCESSIBLE_OWNER_REQUESTED:
			return Object.assign({}, state, {
				hasError: false,
				errorMessage: '',
				isFetching: true,
			});
		case FETCH_ACCESSIBLE_OWNER_RECEIVED:
			return Object.assign({}, state, {
				isFetching: false,
				data: action.payload,
			});
		case FETCH_ACCESSIBLE_OWNER_FAILURE:
			return Object.assign({}, state, {
				hasError: true,
				errorMessage: action.errorMessage,
				isFetching: false,
			});
		default:
			return state;
	}
};

export default accessibleOwnerReducer;