import React from 'react';
import {calculateGridColumnSize} from '../../utils';

const TableColumn = (props) => (<td className={`${props.size} wide column`}>{props.value}</td>);

const TableRow = (props) => {
	//TODO adding key prop to the TableColumn component
	const columnItems = props.items.map((item, position) => {
		return (
			<TableColumn key={position} size={calculateGridColumnSize(props.items, position)}
						 value={item}/>
		);
	});

	return (
		<tr>{columnItems}</tr>
	);
};

const Table = (props) => {
	//TODO adding key prop to the TableRow component
	const rowItems = props.rows.map(row => <TableRow key={row} items={row}/>);

	return (
		<table className='customize-table ui very basic celled table'>
			<tbody>
				{rowItems}
			</tbody>
		</table>
	);
};

export default Table;