import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {getAllProjects, createProject, selectProject} from "../actions/projectAction";
import RepositoryModalForm from "./Repository/RepositoryModalForm";
import DirectoryContextMenu from "./Repository/DirectoryContextMenu";
import {ContextMenuTrigger} from "react-contextmenu";
import axios from "../apis/apiclient";

class UserProfile extends React.Component {
	componentDidMount() {
		this.props.getAllProjects(this.props.companyName);
	}


	onShowCreateForm = () => {
		window.$('#repository-modal-form.ui.modal').modal({
			onApprove : () => false,
			onDeny: () => false,
		}).modal('show');
	};

	onSelectProject = (project) => {
		this.props.selectProject(project);
	};

	handleDelete = (project) => {
		const url = `project`;
		axios.delete(url, { params:{
				company: project.company,
				name: project.name,
			}})
			.then(res => {
				window.location.reload(true);
			})
	};


	render() {
		const {projects} = this.props;
		return (
			<div className='ui segment repositories'>
				<RepositoryModalForm
					company = {this.props.companyName}
				/>
				{projects.isFetching &&
					<div className="ui active inverted dimmer">
						<div className="ui text large loader">Loading</div>
					</div>
				}
				{!projects.isFetching && projects.hasError &&
					<div className="ui warning message">
						<div className="header">
							{projects.hasError.errorMessage}
						</div>
					</div>
				}
				{!projects.isFetching && !projects.hasError &&
				<>
					<button onClick={this.onShowCreateForm} className="ui positive button">
						Create Project
					</button>
					<table id="projects-table" className="ui fixed single line table">
						<thead>
						<tr>
							<th style={{width:'50px'}} />
							<th>Name</th>
						</tr>
						</thead>
						<tbody>
						{projects.data.map(project => {
							const menuId = 'directory-context-menu' + project.name;
								return(
									<Fragment key={project.name}>
										<DirectoryContextMenu
											id={menuId}
											onDelete={() => this.handleDelete(project)}
										/>
										<tr key={project._id} onClick={() => this.onSelectProject(project)} >
											<td>
												<ContextMenuTrigger id={menuId}><i className="database icon"/></ContextMenuTrigger>
											</td>
											<td>
												<ContextMenuTrigger id={menuId}>{project.name}</ContextMenuTrigger>
											</td>
										</tr>
									</Fragment>
								);
							}
						)}
						</tbody>
					</table>
				</>
				}
			</div>
		);
	}
}

const mapStateToProps = ({user, menuItems, projects}) => {
	return {
		menuItems: Object.keys(menuItems),
		companyName: user.company_name,
		companyLogo: user.company_logo,
		projects: projects
	};
};

export default connect(mapStateToProps, {getAllProjects, selectProject, createProject})(UserProfile);