import {
  FETCH_PROJECTS_REQUESTED,
  FETCH_PROJECTS_RECEIVED,
  FETCH_PROJECTS_FAILURE,
  PROJECTS_CREATED,
  PROJECTS_SELECTED,
} from '../actions/types';

const initialState = {
  hasError: false,
  errorMessage: '',
  isFetching: false,
  selectedProject: null,
  data:[],
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECTS_REQUESTED:
      return Object.assign({}, state, {
        hasError: false,
        errorMessage: '',
        isFetching: true,
      });
    case FETCH_PROJECTS_RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.payload,
      });
    case FETCH_PROJECTS_FAILURE:
      return Object.assign({}, state,{
        hasError: true,
        errorMessage: action.errorMessage,
        isFetching: false,
      });
    case PROJECTS_CREATED:
      return Object.assign({}, state, {
        data: [...state.data, action.payload]
      });
    case PROJECTS_SELECTED:
      return Object.assign({}, state, {
        selectedProject: action.payload,
      });
    default:
      return state;
  }
};

export default projectReducer;