import axios from '../../../apis/apiclient';
import { SubmissionError } from 'redux-form';
import {
	PROJECTS_CREATED,
} from '../../../actions/types';

export default (values, dispatch, props) => {
		const url = '/project';
		const project = {
			company: props.company,
			name: values.name
		};
		return axios.post(url, project)
			.then(response => {
				dispatch({
					type: PROJECTS_CREATED,
					payload: project,
				});
				window.$('#repository-modal-form.ui.modal').modal().modal('hide');
			})
			.catch(err => {
				const error = {};
				if(err.response.data)
					error._error = err.response.data;
				throw new SubmissionError(error);
			});
};