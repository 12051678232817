import {
  FETCH_FILES_REQUESTED,
  FETCH_FILES_RECEIVED,
  FETCH_FILES_FAILURE,
  FILES_CREATED,
} from './types';
import axios from '../apis/apiclient';
import history from '../history';

export const getAllFiles = (project) =>  dispatch => {
  dispatch({
    type: FETCH_FILES_REQUESTED,
  });

  axios.get('/project/file-list', { params:{ company: project.company, name: project.name }})
    .then(response => {
      dispatch({
        type: FETCH_FILES_RECEIVED,
        payload: response.data,
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_FILES_FAILURE,
        errorMessage: err.response.data,
      });
    });
};


export const createFile = (filename) => dispatch => {

};
