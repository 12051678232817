import
{
	DATE_TAGS_FAILED,
	DATE_TAGS_RECEIVED,
	DATE_TAGS_REQUESTED
} from '../../actions/types';

const initialState = {
	data: [],
	isFetching: false,
	hasError: false,
	errorMessage: '',
};

const dateTags = (state = initialState, action) => {
	switch (action.type) {
		case DATE_TAGS_REQUESTED:
			return Object.assign({}, state, {
				isFetching: true,
				hasError: false,
				errorMessage: '',
			});
		case DATE_TAGS_RECEIVED:
			return Object.assign({}, state, {
				data: action.payload,
				isFetching: false,
			});
		case DATE_TAGS_FAILED:
			return Object.assign({}, state, {
				isFetching: false,
				hasError: true,
				errorMessage: action.errorMessage,
			});
		default:
			return state;
	}
};

export default dateTags;

