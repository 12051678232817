import {
	Date_TAG_SELECT,
	DATE_TAG_UNSELECT,
} from '../../actions/types';

const initialState = {
	isSelected: false,
	date: null,
};

const selectedDateTag = (state = initialState, action) => {
	switch (action.type) {
		case Date_TAG_SELECT:
			return Object.assign({}, state, {
				isSelected: true,
				date: action.payload,
			});
		case DATE_TAG_UNSELECT:
			return Object.assign({},state, {
				isSelected: false,
				date: null,
			});
		default:
			return state;
	}
};

export default selectedDateTag;